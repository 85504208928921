import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Layout from "../components/layout"
import SEO from '../components/seo'
import NewsListItem from '../components/news-list-item'
import Img from "gatsby-image";

const ArticleTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO
          title={data.article.title}
          description={data.article.body.value}
      />
      <div className="container content-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.article.title}
          />

          <h1 className="title is-1">{data.article.title}</h1>

          <div className="date">
            <span>{data.article.created}</span>
          </div>
        </section>

        <section className="section page-content">
          <div className="columns">
            <div className="column is-12">
              <div className="content" dangerouslySetInnerHTML={{__html: data.article.body.value }} />
            </div>
          </div>
        </section>
      </div>

      <div className="memento has-background-light">
        <div className="container">
          <section className="section is-medium">
            <div id="news-list" className="container other-articles">
              <h2 className="title is-2">A lire également</h2>
              <div className="columns is-multiline">
                {data.otherArticles.edges.map(({ node }, index) => (
                  <NewsListItem key={index} node={node} columnType={4} textLimit={120} cssTruncate={false} />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query($internalId: Int!) {
    article: nodeArticle(
      status: {eq: true},
      drupal_internal__nid: {eq: $internalId}
    ) {
      title
      body {
        value,
      }
      created(formatString: "DD MMMM YYYY", locale: "fr")
    }
    otherArticles: allNodeArticle(
      filter: {
        status: {eq: true},
        publication_lte_now: {eq: false},
        drupal_internal__nid: {ne: $internalId}
      }, 
      sort: {fields: created, order: DESC}
      limit: 3
    ) {
      edges {
        node {
          id
          title
          created(formatString: "DD MMMM YYYY", locale: "fr")
          path {
            alias
          }
          body {
           value,
           summary
          }
        }
      }
    }
  }
`
